import { getFlexBasis, useRouterLinkContext } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useState } from 'react';
import { SxStyleProp } from 'theme-ui';

import { DateRangeContext, DateRange } from './DateRangeContext';
import { HotelOnlySearchButton } from './search-buttons';
import { useSearchConfigurationContext } from './SearchConfigurationContext';
import { SearchFormDateRangeInput } from './SearchFormDateRangeInput';
import { useSearchResultsPageUrl } from './useSearchResultsPageUrl';
import { AccommodationSuggestion } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { DestinationMultiSelect } from '@Components/DestinationMultiSelect/DestinationMultiSelect';
import { RoomsInput } from '@Components/RoomsEditor/RoomsInput';
import { MINIMUM_BOOKABLE_DATE_OFFSET } from '@Constants/MinimumBookableDate';
import { HotelOnlySearchContextProvider } from '@Contexts/SearchTypeContext/SearchTypeContext';
import { utcDate } from '@Dates/dates';
import { useStoreContext } from '@Stores/StoreContext';
import { Fieldset } from '@UX/Forms/Fieldset/Fieldset';

const labelStyles: SxStyleProp = {
  display: 'block',
  marginBottom: ['3xs', null, 0],
};

/**
 * By default the first available day is "today + 2 days", but when `earliestHotelOnlySalesDate`
 * is provided and that's later than the default then we use that as first available day.
 */
export const calculateFirstAvailableDay = (
  earliestHotelOnlySalesDate: string | undefined,
): Dayjs => {
  const defaultHoSales = dayjs().add(MINIMUM_BOOKABLE_DATE_OFFSET, 'day').startOf('day');

  if (earliestHotelOnlySalesDate) {
    const earliestHoSales = utcDate(earliestHotelOnlySalesDate);

    return earliestHoSales.isAfter(defaultHoSales) ? earliestHoSales : defaultHoSales;
  }

  return defaultHoSales;
};

export const HotelOnlySearchForm: React.FC<ClassNameProps> = ({ className }) => {
  const { earliestHotelOnlySalesDate, showDestinationInput, showFullFormMobile } =
    useSearchConfigurationContext();
  const [dateRange, setDateRange] = useState<DateRange>({ startDate: null, endDate: null });
  const { searchSelection } = useStoreContext();
  const { t } = useTranslation();
  const getSearchResultsPageUrl = useSearchResultsPageUrl();

  const { useHref } = useRouterLinkContext();

  const handleHotelClick = useCallback((hotel: AccommodationSuggestion) => {
    const selection = searchSelection.getState();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    window.location.href = useHref(
      getSearchResultsPageUrl({
        type: 'HOSRP',
        selection,
        dateRange,
        masterId: hotel.id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const firstAvailableDay = calculateFirstAvailableDay(earliestHotelOnlySalesDate);

  return (
    <HotelOnlySearchContextProvider>
      <DateRangeContext.Provider
        value={{
          dateRange,
          setDateRange,
        }}
      >
        <article className={className}>
          <section
            sx={{
              '&': [
                {
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  width: '100%',
                },
              ],
              label: showFullFormMobile
                ? ({
                    border: 0,
                    clip: ['rect(0 0 0 0)', 'none'],
                    height: ['1px', 'initial'],
                    margin: ['-1px', 'initial'],
                    overflow: ['hidden', 'initial'],
                    padding: 0,
                    position: ['absolute', 'initial'],
                    width: ['1px', 'initial'],
                  } as SxStyleProp)
                : null,
              '& > *': {
                flexGrow: 0,
                flexShrink: 0,
                flexBasis: [
                  '100%',
                  getFlexBasis(showDestinationInput ? 2 : 3, 'xs'),
                  'calc(29% - 6px)',
                ],
              },
            }}
          >
            {showDestinationInput && (
              <Fieldset
                name="destination-input"
                label={t('facets.destinations')}
                sx={{
                  ...labelStyles,
                  maxWidth: ['100%', getFlexBasis(2, 'xs'), '25%'],
                }}
              >
                <DestinationMultiSelect onHotelClick={handleHotelClick} />
              </Fieldset>
            )}

            <Fieldset
              name="departure-date-input"
              label={`${t('checkIn')} - ${t('checkOut')}`}
              sx={labelStyles}
            >
              <SearchFormDateRangeInput firstAvailableDay={firstAvailableDay} />
            </Fieldset>

            <Fieldset
              name="rooms-input"
              label={t('facets.rooms')}
              sx={labelStyles}
            >
              <RoomsInput />
            </Fieldset>
            <div
              sx={{
                maxWidth: [null, '140px'],
              }}
            >
              <div sx={{ display: ['none', 'block'] }}>&nbsp;</div>
              <HotelOnlySearchButton />
            </div>
          </section>
        </article>
      </DateRangeContext.Provider>
    </HotelOnlySearchContextProvider>
  );
};
