import { Dayjs } from 'dayjs';
import { useContext, createContext } from 'react';

export interface DateRange {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

interface DateRangeContextValue {
  dateRange: DateRange;
  setDateRange: any;
}

export const DateRangeContext = createContext<DateRangeContextValue>({
  dateRange: {
    startDate: null,
    endDate: null,
  },
  setDateRange: () => {},
});

export const useDateRangeContext = () => useContext(DateRangeContext);
