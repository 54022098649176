import { ButtonProps } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { useDateRangeContext } from './DateRangeContext';
import { useSearchResultsPageUrl } from './useSearchResultsPageUrl';
import { ClassNameProps } from '@ComponentProps';
import { Button } from '@Components/Button/Button';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { useSearchSelectionStore } from '@Stores/StoreContext';

interface SearchButtonProps extends ClassNameProps, Pick<ButtonProps, 'internal'> {
  url: string;
  disabled?: boolean;
  onClick?: () => void;
}

const SearchButton: React.FC<SearchButtonProps> = ({
  url,
  'data-id': dataId,
  disabled,
  internal,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="Primary"
      size="48"
      stretch
      data-id={dataId}
      href={disabled ? '' : url}
      internal={internal}
      asAnchor={false}
      sx={{
        width: '100%',
      }}
      onClick={onClick}
    >
      {t('search')}
    </Button>
  );
};

export const HotelOnlySearchButton: React.FC = () => {
  const selection = useSearchSelectionStore((state) => state);
  const { dateRange } = useDateRangeContext();
  const getSearchResultsPageUrl = useSearchResultsPageUrl();

  return (
    <SearchButton
      data-id="ho-search-cta"
      url={getSearchResultsPageUrl({
        type: 'HOSRP',
        selection,
        dateRange,
      })}
      onClick={() => {
        trackEventClick('search-ui-ho-search');
      }}
    />
  );
};
