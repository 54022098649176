import { Icon } from '@loveholidays/design-system';
import React from 'react';

import { Option } from '@UX/Forms/MultiSelect/MultiSelect';
import { MultiSelectOptionType } from '@UX/Forms/MultiSelect/MultiSelectOption';

export const AccomodationOption: React.FC<MultiSelectOptionType> = ({
  option,
}: {
  option: Option;
}) => (
  <div
    sx={{
      display: 'flex',
      flexDirection: ['row-reverse', 'row'],
      justifyContent: ['space-between', 'flex-start'],
      textAlign: 'left',
      alignItems: 'center',
      width: '100%',
    }}
  >
    <div
      sx={{
        minWidth: 25,
        width: 25,
        marginLeft: [40, 0],
        marginRight: [20, 10],
      }}
    >
      <Icon
        name="Content/PlaceHotel"
        size="20"
      />
    </div>
    <div>
      <strong>{option.label}</strong> {option.secondaryLabel}
    </div>
  </div>
);
