import { Icon } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'urql';

import { AccomodationOption } from './AccomodationOption';
import getDestinations from './getDestinations.gql';
import { Query } from '@AuroraTypes';
import { isAnyDestination } from '@Client/utils/isAnyDestination';
import { ClassNameProps } from '@ComponentProps';
import { useSearchTypeContext } from '@Contexts/SearchTypeContext/SearchTypeContext';
import { unique } from '@Core/helpers/array';
import { isQueryResolved } from '@Core/isQueryResolved';
import { useDestinationAutocompleteTracking } from '@Core/tracking/hooks/useDestinationAutocompleteTracking';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { AutocompleteEventType } from '@Core/tracking/types';
import { useSearchAvailabilityStore, useSearchSelectionStore } from '@Stores/StoreContext';
import { MultiSelect, Option } from '@UX/Forms/MultiSelect/MultiSelect';
import { MultiSelectOption, MultiSelectOptionType } from '@UX/Forms/MultiSelect/MultiSelectOption';

interface DestinationMultiSelectProps extends ClassNameProps {
  wrapOnDesktop?: boolean;
  onHotelClick: (hotel: any) => void;
}

export const DestinationMultiSelect: React.FC<DestinationMultiSelectProps> = ({
  children,
  className,
  'data-id': dataId = 'destinations-input',
  wrapOnDesktop,
  onHotelClick,
}) => {
  const searchSelection = useSearchSelectionStore((state) => ({
    boardBasis: state.boardBasis,
    cancellationPolicy: state.cancellationPolicy,
    date: state.date,
    departureAirports: state.departureAirports,
    flexibility: state.flexibility,
    nights: state.nights,
    rooms: state.rooms,
    filters: state.filters,
    source: state.source,
  }));
  const { t } = useTranslation();
  const { origin } = useSearchTypeContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [prevSearchTerm, setPrevSearchTerm] = useState(searchTerm);
  const [pause, setPause] = useState(true);
  const { destinationIds, setDestinationIds, clearPinnedMasterIds } = useSearchSelectionStore(
    (state) => state,
  );

  const [destinationInputTracking, destinationOptionTracking, destinationLoadedTracking] =
    useDestinationAutocompleteTracking('DestinationMultiSelect', [
      AutocompleteEventType.inputChanged,
      AutocompleteEventType.optionSelected,
      AutocompleteEventType.optionsLoaded,
    ]);

  const [availableDestinations] = useSearchAvailabilityStore((state) => [
    state.destinations,
    state.nights,
  ]);

  const previousDestinationList = useRef<Option[]>([]);

  const preSelectedDestinations =
    availableDestinations.map(
      (d) =>
        ({
          value: d.id,
          label: d.name,
          available: true,
        }) as Option,
    ) || [];

  const [{ data, fetching }] = useQuery<Query>({
    pause,
    query: getDestinations,
    variables: {
      query: searchTerm,
      ...(origin === 'DP' && {
        searchSelection,
      }),
    },
  });

  const isLoading = !isQueryResolved(fetching, data);
  const options: Option[] = [];
  const selectedOptions: Option[] = [];

  const handleHotelClick = (option: Option) => {
    if (option.type !== 'accomodation') {
      return;
    }

    const hotel = data?.Lookup.destinations.find((item) => item.id === option.value);
    destinationOptionTracking({
      searchTerm,
      options,
      selectedOptions: [option, ...selectedOptions],
      isOptionsLoaded: !isLoading,
      isListOpened: !pause,
    });
    onHotelClick(hotel);
  };

  let destinations: Option[] = [];

  if (!isLoading) {
    destinations = (data?.Lookup?.destinations || [])
      .map((item: any) => {
        const isAccomodation = !!item.accommodation;
        const destinationDetail = item.accommodation ? item.accommodation : item.destination;

        if (!destinationDetail) {
          return null;
        }

        const option: Option = {
          value: item.id,
          label: destinationDetail.name,
          secondaryLabel: destinationDetail.locationLabel,
          available: isAccomodation ? true : item.available,
          type: isAccomodation ? 'accomodation' : 'destination',
          onClick: isAccomodation ? handleHotelClick : undefined,
        };

        return option;
      })
      .filter(Boolean) as Option[];
  }

  const onDestinationSearchTermChanged = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  const filterSelectedOptions = (selectedOptions: Option[]) => {
    if (selectedOptions.length <= 1) {
      return selectedOptions;
    }

    const lastSelectedOption = selectedOptions[selectedOptions.length - 1];
    const isLastSelectedOptionAnyDestination = isAnyDestination(String(lastSelectedOption.value));

    return isLastSelectedOptionAnyDestination
      ? selectedOptions.filter((o) => isAnyDestination(String(o.value)))
      : selectedOptions.filter((o) => !isAnyDestination(String(o.value)));
  };

  const allDestinations = [
    ...preSelectedDestinations,
    ...destinations,
    ...previousDestinationList.current,
  ];

  selectedOptions.push(
    ...(destinationIds
      .map((id) => allDestinations.find((d) => d.value === id))
      .filter(Boolean) as Option[]),
  );

  const optionRenderer = ({ option, isSelected, isFocused }: MultiSelectOptionType) => {
    const isAccomodation = option.type === 'accomodation';
    const label = isAccomodation ? (
      <AccomodationOption option={option} />
    ) : (
      <div
        sx={{
          color: !option.available ? 'textDimmedmedium' : 'textDefault',
          textAlign: 'left',
          fontWeight: isSelected ? 'bold' : 'normal',
        }}
      >
        {option.label}
        {option.secondaryLabel && <span>, {option.secondaryLabel}</span>}
      </div>
    );

    return (
      <MultiSelectOption
        option={option}
        isSelected={isSelected}
        isFocused={isFocused}
        canBeChecked={!isAccomodation}
      >
        {label}
      </MultiSelectOption>
    );
  };

  if (destinations.length > 0) {
    options.push(
      ...selectedOptions,
      ...destinations.filter((dest) => !selectedOptions.some((s) => s.value === dest.value)),
    );
    previousDestinationList.current = destinations;
  }

  useEffect(() => {
    if (searchTerm === prevSearchTerm) {
      return;
    }
    destinationInputTracking({
      searchTerm,
      options,
      selectedOptions,
      isOptionsLoaded: !isLoading,
      isListOpened: !pause,
    });
    setPrevSearchTerm(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    destinationLoadedTracking({
      searchTerm,
      options,
      selectedOptions,
      isOptionsLoaded: !isLoading,
      isListOpened: !pause,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleSelect = (selectedOptions: Option[]) => {
    const filteredOptions = filterSelectedOptions(selectedOptions);

    clearPinnedMasterIds();
    setDestinationIds(unique(filteredOptions.map((option) => option.value)));
    destinationOptionTracking({
      searchTerm,
      options,
      selectedOptions,
      isOptionsLoaded: !isLoading,
      isListOpened: !pause,
    });
  };

  const memoDep = JSON.stringify({
    destinations,
    selectedOptions,
    isLoading,
    prev: previousDestinationList.current,
  });

  return useMemo(
    () => (
      <MultiSelect
        isLoading={isLoading}
        data-id={dataId}
        className={className}
        options={options}
        selectedOptions={selectedOptions}
        inputPlaceholder={t('anyDestination')}
        inputPlaceholderMobile={t('destinationPlaceholder')}
        placeholderAfterSelections={t('addAnother')}
        icon={
          <Icon
            name="Content/Place"
            size="20"
          />
        }
        onOptionsUpdate={handleSelect}
        modalTitle={t('facets.departureAirport')}
        onOptionsSearchTermChanged={onDestinationSearchTermChanged}
        wrapOnDesktop={wrapOnDesktop}
        optionRenderer={optionRenderer}
        onOpen={() => setPause(false)}
        onClose={() => setPause(true)}
        onInputClick={() => {
          trackEventClick('search-ui-destination-input');
        }}
        filterOptions={filterSelectedOptions}
      >
        {children}
      </MultiSelect>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memoDep],
  );
};
