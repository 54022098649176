import {
  Icon,
  useClickOutside,
  useOnKeyEvent,
  keyboardOutline,
  Key,
  ModalHeader,
} from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import dayjs, { Dayjs } from 'dayjs';
import React, { useRef } from 'react';

import { useDateRangeContext } from './DateRangeContext';
import { SearchFormDateRangeCalendar } from './SearchFormDateRangeCalendar';
import { ClassNameProps } from '@ComponentProps';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useFocusOut } from '@Components/Modal/useFocusOut';
import { useModal } from '@Components/Modal/useModal';
import { useBreakpoint } from '@Core/useBreakpoint';
import { useDate } from '@Dates/useDateHook';
import { IconInput } from '@UX/Forms/IconInput/IconInput';
import { Popover } from '@UX/Tooltip/Popover';

interface SearchFormDateRangeInputProps extends ClassNameProps {
  firstAvailableDay?: Dayjs;
}

export const SearchFormDateRangeInput: React.FC<SearchFormDateRangeInputProps> = ({
  firstAvailableDay = dayjs(),
  className,
}) => {
  const { t } = useTranslation();
  const {
    dateRange: { startDate, endDate },
  } = useDateRangeContext();
  const { formatCalendarDate } = useDate();
  const { isDesktop } = useBreakpoint();
  const [isModalOpen, setModalOpen, setModalClose] = useModal();
  const ref = useRef(null);

  useClickOutside({
    ref,
    isActive: isModalOpen && !!isDesktop(),
    onClick: setModalClose,
  });
  useOnKeyEvent(Key.Escape, setModalClose);
  useFocusOut(
    ref,
    () => {
      if (isModalOpen && !!isDesktop()) {
        setModalClose();
      }
    },
    [isModalOpen],
  );

  return (
    <div
      ref={ref}
      sx={{ position: 'relative' }}
    >
      <IconInput
        data-id="date-range-input"
        className={className}
        onClick={setModalOpen}
        role="button"
        sx={{
          ...keyboardOutline,
        }}
        icon={
          <Icon
            name="Content/InputDate"
            size="20"
          />
        }
      >
        <div data-id="date-range-input-text">
          {startDate && endDate ? (
            <span sx={{ color: 'textDefault' }}>
              {formatCalendarDate(startDate)} - {formatCalendarDate(endDate)}
            </span>
          ) : (
            t('anyDate')
          )}
        </div>
      </IconInput>

      {isModalOpen && !isDesktop() && (
        <LoadableModal
          onClose={setModalClose}
          data-id="modal-container"
          show
          Header={
            <ModalHeader
              layout="medium"
              onClose={setModalClose}
            />
          }
          Content={
            <SearchFormDateRangeCalendar
              isMobile={!isDesktop()}
              sx={{
                marginTop: 'xl',
              }}
              onDateRangeSelected={setModalClose}
              firstAvailableDay={firstAvailableDay}
            />
          }
        />
      )}

      {isModalOpen && !!isDesktop() && (
        <Popover>
          <SearchFormDateRangeCalendar
            onDateRangeSelected={setModalClose}
            firstAvailableDay={firstAvailableDay}
          />
        </Popover>
      )}
    </div>
  );
};
