import { Icon, TriggerButton, TriggerEvent } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import { Dayjs } from 'dayjs';
import React, { useState } from 'react';

import { useDateRangeContext, DateRange } from './DateRangeContext';
import { DayGrid, RenderDayParams } from '../Calendar/DayGrid/DayGrid';
import { MonthSelect } from '../Calendar/MonthSelect/MonthSelect';
import { ClassNameProps } from '@ComponentProps';
import { Day } from '@Components/Calendar/Day/Day';
import { chevronStyles } from '@Components/Calendar/MonthSelect/MonthSelect';
import { addSentryBreadcrumb } from '@Core/addSentryBreadcrumb';
import { useDate } from '@Dates/useDateHook';

const MAX_SELECTED_DAYS = 31;

const generateYearMonths = (firstAvailableDay: Dayjs) => {
  const startDate = firstAvailableDay.startOf('month');

  return [...Array(19).keys()].map((count) => startDate.add(count, 'month').format('YYYY-MM'));
};

interface SearchFormDateRangeCalendarProps extends ClassNameProps {
  firstAvailableDay: Dayjs;
  onDateRangeSelected?: (e: TriggerEvent) => void;
  isMobile?: boolean;
}

export const SearchFormDateRangeCalendar: React.FC<SearchFormDateRangeCalendarProps> = ({
  firstAvailableDay,
  onDateRangeSelected = () => {},
  className,
  isMobile,
}) => {
  const { t } = useTranslation();
  const { localizedFormat } = useDate();
  const { dateRange: dateRangeContextValue, setDateRange: setDateRangeContext } =
    useDateRangeContext();

  const availableYearMonths = generateYearMonths(firstAvailableDay);

  const [{ startDate, endDate }, setDateRangeState] = useState<DateRange>(dateRangeContextValue);

  const getInitialYearMonth = () => {
    if (startDate) {
      return startDate.format('YYYY-MM');
    }

    return availableYearMonths[0];
  };

  const [activeYearMonth, setActiveYearMonth] = useState(getInitialYearMonth());
  const currentYearMonthIndex = availableYearMonths.findIndex((value) => value === activeYearMonth);

  const getMonthsAhead = (ahead: number) => availableYearMonths[currentYearMonthIndex + ahead];

  const previousMonth = availableYearMonths
    .filter((yearMonth, index) => index < currentYearMonthIndex)
    .pop();

  const onLeftChevronClick = () => {
    if (previousMonth) {
      setActiveYearMonth(previousMonth);
      addSentryBreadcrumb('Previous month clicked', { value: previousMonth });
    }
  };

  const onRightChevronClick = () => {
    if (getMonthsAhead(2)) {
      setActiveYearMonth(getMonthsAhead(1));
      addSentryBreadcrumb('Next month clicked', { value: getMonthsAhead(1) });
    }
  };

  const onDayClick = (e: TriggerEvent, date: Dayjs) => {
    if (startDate?.isSame(date) && !(startDate && endDate)) {
      return;
    }

    if (!startDate || (startDate && endDate) || date.isBefore(startDate)) {
      setDateRangeState({
        startDate: date,
        endDate: null,
      });

      return;
    }

    setDateRangeState({
      startDate,
      endDate: date,
    });

    onDateRangeSelected(e);
    setDateRangeContext({
      startDate,
      endDate: date,
    });
  };

  const renderDay = ({ date, dayIndex }: RenderDayParams) => {
    const isSelected = (startDate && date.isSame(startDate)) || (endDate && date.isSame(endDate));
    const isInRange = startDate && endDate && startDate.isBefore(date) && date.isBefore(endDate);

    const isDisabled =
      date.isBefore(firstAvailableDay) ||
      (!!startDate && !endDate && date.isAfter(startDate?.add(MAX_SELECTED_DAYS, 'day')));

    return (
      <Day
        data-id={isSelected ? 'day-selected' : 'day'}
        disabled={isDisabled}
        aria-label={localizedFormat(date)}
        onClick={(e: TriggerEvent) => {
          onDayClick(e, date);
        }}
        sx={{
          fontSize: 'm',
          color:
            ((isSelected || isInRange) && 'textDefault') ||
            (isDisabled && 'inputDisabled') ||
            'link',
          backgroundColor:
            (isSelected && 'selectionTag') || (isInRange && 'selectionTag') || 'white',
          paddingTop: '22%',
          paddingBottom: '11%',
          fontWeight: isSelected ? 'bold' : 'normal',
          '&:hover': !isDisabled
            ? {
                backgroundColor: 'selectionTag',
                color: 'textDefault',
              }
            : null,
        }}
      >
        {dayIndex + 1}
      </Day>
    );
  };

  return (
    <div
      className={className}
      sx={{
        width: ['100%', 'auto'],
      }}
    >
      <strong
        sx={{
          display: 'block',
          textAlign: 'center',
          marginBottom: 'xs',
          fontSize: 'xxl',
        }}
      >
        {startDate && !endDate ? t('checkOut') : t('checkIn')}
      </strong>

      {isMobile && (
        <MonthSelect
          availability={availableYearMonths.slice(0, availableYearMonths.length - 1).reduce(
            (acc, curr) => {
              acc[curr] = true;

              return acc;
            },
            {} as { [key: string]: boolean },
          )}
          selectedYearMonth={activeYearMonth}
          onChange={setActiveYearMonth}
          sx={{
            marginBottom: 'xs',
          }}
        />
      )}

      <div
        sx={{
          display: 'flex',
        }}
      >
        {!isMobile && (
          <TriggerButton
            data-id="previous-month"
            aria-label={t('previousMonth')}
            onTrigger={onLeftChevronClick}
          >
            <Icon
              name="Actions/MovePrevious"
              size="28"
              sx={chevronStyles(!previousMonth)}
            />
          </TriggerButton>
        )}
        <DayGrid
          yearMonth={activeYearMonth}
          renderDay={renderDay}
          displayMonth
          sx={{
            marginRight: [0, 'xs', 'xs'],
            maxWidth: [null, '280px'],
          }}
        />
        <DayGrid
          yearMonth={getMonthsAhead(1)}
          renderDay={renderDay}
          displayMonth
          sx={{
            display: ['none', 'table'],
            maxWidth: '280px',
          }}
        />
        {!isMobile && (
          <TriggerButton
            data-id="next-month"
            aria-label={t('nextMonth')}
            onTrigger={onRightChevronClick}
          >
            <Icon
              name="Actions/MoveNext"
              size="28"
              sx={{
                ...chevronStyles(!getMonthsAhead(2)),
              }}
            />
          </TriggerButton>
        )}
      </div>
    </div>
  );
};
